<template>
  <div class="grid grid-cols-12 items-center">
    <div class="col-span-1 text-gray-600">#{{ id }}</div>
    
    <div class="col-span-3">
      <OrderDate :date="date" />
    </div>
    
    <div class="hidden smd:block col-span-2">
      <OrderStatus :status="status" />
    </div>
    
    <div class="hidden smd:block col-span-2">
      <OrderStatus :status="paid" type="payment" />
    </div>
    
    <div class="col-span-2">
      <span class="font-medium">{{ formatNumber(Number(total) + Number(delivery)) }}</span>
      <span class="text-gray-500 text-sm">{{ content.iqd }}</span>
    </div>
    
    <div class="col-span-2 text-right">
      <router-link
        :to="{ name: routeName, params: { id } }"
        class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 rounded-md hover:bg-gray-100 transition-colors duration-150"
      >
        {{ content.view }}
        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
import OrderDate from "../../../components/ui/Order/OrderDate.vue";
import OrderStatus from "../../../components/ui/Order/OrderStatus.vue";
import { format } from "/src/assets/numberFormat";

export default {
  components: { OrderStatus, OrderDate },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    paid: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      required: true
    },
    delivery: {
      type: Number,
      default: 0
    },
    routeName: {
      type: String,
      required: true
    }
  },
  inject: ["content"],
  methods: {
    formatNumber(number) {
      return format(number);
    }
  }
};
</script>


