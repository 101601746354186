<template>
  <div class="layout-padding">
    <!-- Header Section -->
    <div class="flex justify-between flex-wrap items-center mb-8">
      <h1 class="page-title me-4 mb-3">{{ content.yourOrderHistory }}</h1>
      <div class="flex items-center flex-wrap gap-3">
        <Pill
          :class="{ 'active-pill': selectedSection == 'shopItems' }"
          :text="content.shopItems"
          @click="selectedSection = 'shopItems'"
        />
        <Pill
          :class="{ 'active-pill': selectedSection == 'workshops' }"
          :text="content.workshops"
          @click="selectedSection = 'workshops'"
        />
        <Pill
          :class="{ 'active-pill': selectedSection == 'subscriptions' }"
          :text="content.subscriptions"
          @click="selectedSection = 'subscriptions'"
        />
        <Pill
          :class="{ 'active-pill': selectedSection == 'personalized' }"
          :text="content.personalized"
          @click="selectedSection = 'personalized'"
        />
      </div>
    </div>

    <!-- Table Section -->
    <div class="order-history-container bg-white rounded-lg shadow-sm">
      <!-- Table Header -->
      <div class="order-table-header grid grid-cols-12 bg-gray-50 p-4 rounded-t-lg">
        <div class="col-span-1 font-medium">#</div>
        <div class="col-span-3 font-medium">{{ content.date }}</div>
        <div class="hidden smd:block col-span-2 font-medium">{{ content.status }}</div>
        <div class="hidden smd:block col-span-2 font-medium">{{ content.paid }}</div>
        <div class="col-span-2 font-medium">{{ content.total }}</div>
        <div class="col-span-2 font-medium text-right">{{ content.actions }}</div>
      </div>

      <!-- Loading State -->
      <OrderCardSkeleton v-if="loading" />

      <!-- Empty State -->
      <div 
        v-else-if="getCurrentItems.length === 0" 
        class="p-8 text-center text-gray-500"
      >
        <p class="text-lg">{{ content.noOrders }}</p>
      </div>

      <!-- Table Content -->
      <div v-else class="divide-y divide-gray-100">
        <OrderCard
          v-for="item in getCurrentItems"
          :key="item.id"
          :id="item.id"
          :type="getCurrentType"
          :date="item.date"
          :status="item.status"
          :paid="item.paid"
          :total="getCurrentTotal(item)"
          :delivery="item?.city?.price ?? 0"
          :route-name="getCurrentRouteName"
          class="p-4 hover:bg-gray-50 transition-colors duration-150"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pill from "/src/components/ui/Pill.vue";
import OrderCard from "./OrderCard.vue";
import OrderCardSkeleton from "/src/components/ui/Skeletons/OrderCardSkeleton.vue";

export default {
  components: {
    Pill,
    OrderCard,
    OrderCardSkeleton,
  },
  inject: ["content"],
  
  data() {
    return {
      loading: true,
      selectedSection: "shopItems",
    };
  },

  computed: {
    orders() {
      return this.$store.getters["orders/orders"];
    },
    workshops() {
      return this.$store.getters["workshops/tickets"];
    },
    personalized() {
      return this.$store.getters["personalized/requests"];
    },
    subscriptions() {
      return this.$store.getters["subscription/mySubs"];
    },
    getCurrentItems() {
      const itemsMap = {
        shopItems: this.orders,
        workshops: this.workshops,
        subscriptions: this.subscriptions,
        personalized: this.personalized,
      };
      return itemsMap[this.selectedSection] || [];
    },
    getCurrentType() {
      return this.selectedSection === 'shopItems' ? 'shop' : this.selectedSection;
    },
    getCurrentRouteName() {
      const routeMap = {
        shopItems: 'orderDetails',
        workshops: 'ticketOrderDetails',
        subscriptions: 'subscriptionOrderDetails',
        personalized: 'personalizedOrderDetails',
      };
      return routeMap[this.selectedSection];
    },
  },

  methods: {
    getCurrentTotal(item) {
      if (this.selectedSection === 'workshops') {
        return item.ticket.price;
      }
      if (this.selectedSection === 'personalized') {
        return item.price;
      }
      return item.total;
    },
  },

  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("orders/orders"),
        this.$store.dispatch("workshops/myTickets"),
        this.$store.dispatch("subscription/mySubscriptions"),
        this.$store.dispatch("personalized/requests"),
      ]);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.order-history-container {
  border: 1px solid #eee;
  border-radius: 0.5rem;
  overflow: hidden;
}

.order-table-header {
  border-bottom: 1px solid #eee;
  color: #374151;
}

.page-title {
  color: #111827;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .order-table-header {
    padding: 0.75rem;
  }
  
  .page-title {
    font-size: 1.5rem;
  }
}
</style>
